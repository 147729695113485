export enum API_ROUTES {
    // APP
    LIST_ALL_MODULES = 'app/modules',
    // User
    LIST_USERS_RECORD = 'dash/users',
    LIST_USER_CLASSES = 'dash/users/classes',
    LIST_ACTIVE_USERS_RECORD = 'dash/users/active',
    VIEW_USER_RECORD = 'dash/users/view',
    ADD_USER_RECORD = 'dash/users',
    UPDATE_USER_RECORD = 'dash/users',
    CHANGE_USER_PASSWORD = 'dash/users/change',
    RESET_USER_PASSWORD = 'dash/users/reset',
    ADD_USER__CLASS = 'dash/users/class',
    // CLient API
    CLIENT_VIEW_RECORD = 'app/client',





    // Academic
    ACADEMIC_LIST_RECORDS = 'dash/academic',
    ACADEMIC_ACTIVE_RECORD = 'dash/academic/active',
    ACADEMIC_INACTIVE_RECORD = 'dash/academic/inactive',
    ACADEMIC_VIEW_RECORD = 'dash/academic/view',
    ACADEMIC_ADD_RECORD = 'dash/academic',
    ACADEMIC_UPDATE_RECORD = 'dash/academic',
    ACADEMIC_ACTIVE_UPDATE_RECORD = 'dash/academic/active',

    // Classes
    CLASS_LIST_RECORDS = 'dash/classes',
    // CLASS_LIST_ACTIVE_RECORDS = 'dash/classes/active',
    SECONDADRY_LIST_ACTIVE_RECORDS = 'dash/classes/all-secondary',
    INTERMEDIATE_LIST_ACTIVE_RECORDS = 'dash/classes/all-intermediate',
    CLASS_ADD_RECORD = 'dash/classes',
    CLASS_UPDATE_RECORD = 'dash/classes',
    // Subjects
    SUBJECT_LIST_RECORDS = 'dash/subjects',
    SUBJECT_LIST_ACTIVE_RECORDS = 'dash/subjects/active',
    SUBJECT_ADD_RECORD = 'dash/subjects',
    SUBJECT_UPDATE_RECORD = 'dash/subjects',
    // Sections
    SECTIONS_LIST_RECORDS = 'dash/sections',
    SECTIONS_DELETE_RECORDS = 'dash/sections',
    SECTIONS_LIST_ACTIVE_RECORDS = 'dash/sections/active',
    SECTIONS_ADD_RECORD = 'dash/sections',
    SECTIONS_UPDATE_RECORD = 'dash/sections',
    // Exams
    EXAM_LIST_RECORDS = 'dash/exam',
    EXAM_LIST_ACTIVE_RECORDS = 'dash/exam/active',
    EXAM_ADD_RECORD = 'dash/exam',
    EXAM_UPDATE_RECORD = 'dash/exam',
    EXAM_DELETE_RECORD = 'dash/exam',
    // BillTypes
    BILLTYPES_LIST_RECORDS = 'dash/bill-types',
    BILLTYPES_LIST_ACTIVE_RECORDS = 'dash/bill-types/active',
    BILLTYPES_ADD_RECORD = 'dash/bill-types',
    BILLTYPES_UPDATE_RECORD = 'dash/bill-types',
    BILLTYPES_DELETE_RECORD = 'dash/bill-types',
    // School
    SCHOOL_GET_RECORD = 'app/school',
    SCHOOL_UPDATE_RECORD = 'app/school',
    SCHOOL_UPLOAD_LOGO = 'app/school/logo',
    // Student
    STUDENT_LIST_RECORDS = 'dash/students',
    STUDENT_LIST_ACTIVE_RECORDS = 'dash/students/active',
    STUDENT_ADD_RECORD = 'dash/students',
    STUDENT_IMPORT_RECORD = 'dash/students/import',
    PARENT_IMPORT_RECORD = 'dash/parents/import',
    STUDENT_UPDATE_RECORD = 'dash/students',
    STUDENT_UPDATE_CLASS_RECORD = 'dash/students/change-student-class',
    STUDENT_VIEW_RECORD = 'dash/students/view',
    STUDENT_GET_MAXROLLNO = 'dash/students/max-rollno',
    STUDENT_GET_BY_CLASS = 'dash/students/student-by-section',

    STUDENT_GET_BY_CLASSBATCH = 'dash/students/student-by-sectionBatch',


    STUDENT_GET_BY_BILL = 'dash/students/student-by-bill',

    // get sms by filter 
    SMST_GET_BY_FILTER = 'dash/students/smsbyFilter',

    STUDENT_UPDATE_BY_BILL = 'dash/students/student-bill-update',
    STUDENT_REPORT = 'dash/students/student-report',
    STUDENT_TRANSFEREBILL_REPORT = 'dash/students/student-transfere-bill',
    STUDENT_SUMMERY_REPORT = 'dash/students/reports/summery',
    STUDENT_UPLOAD_PHOTO = 'dash/students/photo',
    PASS_STUDENTS = 'dash/students/pass-students',
    STUDENTS_GRADUATED = 'dash/students/graduated',
    STUDENTS_GRADUATED_LIST = 'dash/students/graduated',
    STUDENTS_GRADUATED_VIEW = 'dash/students/graduated/view',
    STUDENTS_GRADUATED_DELETE = 'dash/students/graduated',
    STUDENTS_DELETE_BY_SECTION = 'dash/students/students-section',
    // Parents
    STUDENT_PARENT_RECORD = 'dash/parents',
    STUDENT_PARENT_LIST = 'dash/parents',
    PARENT_UPDATE_RECORD = 'dash/parents',

    // Dashboard

    //DASH-IDENTITY
    DASH_TOTAL_STUDENTS = 'dash/dashboard/total',
    DASH_TOTAL_SECONDARY = 'dash/dashboard/secondary',
    DASH_TOTAL_INTERMEDIATE = 'dash/dashboard/intermediate',
    DASH_TOTAL_FINANCE = 'dash/dashboard/invoice-reciept',

    DASH_TOTAL_SMS = 'dash/dashboard/smsbyFilterDashboard',



    // Financial
    LIST_REVENUE_RECORDS = 'dash/finance/reports/revenue',
    MAIN_EXPENSES_BALANCE = 'dash/finance/reports/main-expenses',
    // STUDENTBill
    GENERATE_BATCH_BILL = 'dash/finance/patch-bill',
    GENERATE_Student_BILL = 'dash/finance/student-bill',
    GENERATE_SINGLE_BILL = 'dash/finance/single-bill',

    // STUDENT MARKS
    GENERATE_MARKS = 'dash/marks',
    MARKS_LIST_RECORDS = 'dash/marks',
    EXAM_RESULT = 'dash/marks/result',
    EXAM_REPORTS = 'dash/marks/report',
    SINGLE_EXAM_REPORTS = 'dash/marks/single-student-result',
    UPDATE_MARKS = 'dash/marks/update',
    Delete_MARKS = 'dash/marks/delete',
    MARKS_VIEW_RECORD = 'dash/marks/view',
    MARKS_UPDATE_RECORD = 'dash/marks',
    MARKS_RECORD_SENDBYSMS = 'dash/marks/send-sms',
    MARKS_RECORD_SENDBYALL = 'dash/marks/send-all',
    MARKS_IMPORT_RECORDS = 'dash/marks/import',
    PASSING_STUDENTS = 'dash/marks/pass-students',
    REPASSING_STUDENTS = 'dash/marks/repass-students',

    // JOURNAL ENTERY
    ENTERY_ADD_RECORD = 'dash/journal',
    ENTERY_UPDATE_RECORD = 'dash/journal',
    ENTERY_LIST_RECORDS = 'dash/journal',
    ENTERY_VIEW_RECORD = 'dash/journal/view',
    // COUNTRIES
    COUNTRY_ADD_RECORD = 'dash/countries',
    COUNTRY_UPDATE_RECORD = 'dash/countries',
    COUNTRIES_LIST_RECORDS = 'dash/countries',
    COUNTRIES_LIST_ACTIVE_RECORDS = 'dash/countries/active',
    ECOUNTRY_VIEW_RECORD = 'dash/countries/view',
    // TRANSFERE
    TRANSFERE_ADD_RECORD = 'dash/transfere',
    TRANSFERE_UPDATE_RECORD = 'dash/transfere',
    TRANSFERE_LIST_RECORDS = 'dash/transfere',
    TRANSFERE_LIST_ACTIVE_RECORDS = 'dash/transfere/active',
    TRANSFERE_VIEW_RECORD = 'dash/transfere/view',
    // LATESTUDENTS
    LATESTUDENTS_ADD_RECORD = 'dash/late-students',
    LATESTUDENTS_UPDATE_RECORD = 'dash/late-students',
    LATESTUDENTS_LIST_RECORDS = 'dash/late-students',
    LATESTUDENTS_LIST_ACTIVE_RECORDS = 'dash/late-students/active',
    LATESTUDENTS_VIEW_RECORD = 'dash/late-students/view',
    // Send Public Massages
    SEND_PUBLIC_MESSAGE = 'dash/message',

    // MESSAGE_TYPE = 'dash/messages/getSmsType',
    MESSAGE_TYPE = 'dash/students/getSmsType',

    // School Payments
    SCHOOL_PAYMENTS_REPORT = 'dash/dashboard/school-payments',
    MERCHANT_PAYMENTS_REPORT = 'dash/dashboard/merchant-payments',
    SCHOOL_PAYMENTS_Expired = 'dash/dashboard/school-expired',
    // Performance Setup
    ADD_PERFORMANCE_SETUP = 'dash/performance',
    GET_ALL_PERFORMANCE_SETUP = 'dash/performance',
    UPDATE_PERFORMANCE_SETUP = 'dash/performance',
    // Performance Categories
    GET_PERFORMANCE_CATEGORIES = 'dash/performance/PerformanceCategories',
    ADD_PERFORMANCE_CATEGORY = 'dash/performance/PerformanceCategories',
    GET_PERFORMANCE_CATEGORY_BY_ID = 'dash/performance/PerformanceCategories/id',
    UPDATE_PERFORMANCE_CATEGORY = 'dash/performance/PerformanceCategories',
    // Students Performance
    ADD_STUDENT_PERFORMANCE = 'dash/performance/studentPerformance',
    GET_STUDENTS_PERFORMANCE = 'dash/performance/studentPerformance',
    GET_STUDENT_PERFORMANCE_BY_ID = 'dash/performance/studentPerformance/id',
    UPDATE_STUDENT_PERFORMANCE = 'dash/performance/studentPerformance',

    // Specific Performance
    ADD_SPECIFIC_PERFORMANCE = 'dash/performance/specific-performance',
    GET_SPECIFIC_PERFORMANCE = 'dash/performance/specific-performance',
    LIST_SPECIFIC_PERFORMANCE = 'dash/performance/list-specific-performance',
    UPDATE_SPECIFIC_PERFORMANCE = 'dash/performance/specific-performance',
    DELETE_SPECIFIC_PERFORMANCE = 'dash/performance/specific-performance',

    // Attendence Types

    DASH_TOTAL_ATTENDENCEE = 'dash/attendence-types/attendncyDash',


    GET_ALL_ATTENDENCE_TYPES = 'dash/attendence-types',
    ADD_ATTENDENCE_TYPE = 'dash/attendence-types',
    UPDATE_ATTENDENCE_TYPE = 'dash/attendence-types',
    DELETE_ATTENDENCE_TYPE = 'dash/attendence-types',
    STUDENT_GET_BY_ATTENDENCE = 'dash/students/student-by-attendence',
    CUSTOM_STUDENT_ATTENDENCE = 'dash/attendences/custom-reg',

    // TimeTable
    GET_ALL_TIMETABLE = 'dash/timetable',
    VIEW_TIMETABLE = 'dash/timetable/view',
    ADD_TIMETABLE = 'dash/timetable',
    UPDATE_TIMETABLE = 'dash/timetable',

    // Complinte
    LIST_COMPLINTES = 'dash/complinte',
    ADD_COMPLINTE = 'dash/complinte',
    UPDATE_COMPLINTE = 'dash/complinte',
    VIEW_COMPLINTE = 'dash/complinte/view',
    DELETE_COMPLINTE = 'dash/complinte',

    // ticket-date
    LIST_TICKET_DATE = 'dash/ticket-date',
    ADD_TICKET_DATE = 'dash/ticket-date',
    UPDATE_TICKET_DATE = 'dash/ticket-date',

}