<div class="logo text-center">
  <a target="_blank" class="simple-text logo-normal">
    {{user?.client_name}} <br>
    {{user?.client_code}}
  </a>
</div>


<div class="sidebar-wrapper">

  <div class="user">
    <div class="photo">
      <img src="./assets/img/user.png" />
    </div>
    <div class="user-info">
      <a data-toggle="collapse" href="#collapseExample" class="collapsed">
        <span>
          {{user.name}}
          <!-- <b class="caret"></b> -->
        </span>
      </a>
    </div>
  </div>
  <div *ngIf="isMobileMenu()">
    <ul class="nav navbar-nav nav-mobile-menu">
      <li class="nav-item">
        <a class="nav-link" style="cursor:pointer;" (click)="logoutAction()">
          <i class="material-icons">power_settings_new</i>
          <p>
            <span class="d-lg-none d-md-block">Logout</span>
          </p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" style="cursor:pointer;" (click)="changePass()">
          <i class="material-icons">settings</i>
          <p>
            <span class="d-lg-none d-md-block">Change Password</span>
          </p>
        </a>
      </li>
    </ul>
  </div>
  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
      <!--If is a single link-->
      <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" class="nav-link">
        <i class="material-icons">{{menuitem.icontype}}</i>
        <p>{{menuitem.title}}</p>
      </a>
      <!--If it have a submenu-->
      <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'" (click)="updatePS()"
        class="nav-link">
        <i class="material-icons">{{menuitem.icontype}}</i>
        <p>{{menuitem.title}}<b class="caret"></b></p>
      </a>

      <!--Display the submenu items-->
      <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
        <ul class="nav">
          <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
            <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link">
              <span class="sidebar-mini">{{childitem.ab}}</span>
              <span class="sidebar-normal">{{childitem.title}}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>